<template>
  <div class='wameed-dashboard-page-content'>


    <section class='wameed-dashboard-page-content_body products_create'>


      <wameed-form @onSubmit='submit'>
        <div slot-scope='{ invalid }'>
          <b-row class='px-2'>
            <b-col lg='12' md='12' class='px-0'>
              <b-card no-body class='wameed-card'>
                <b-card-header class='align-items-start tab-title mb-4'>
                  <h5 class='text-med-20 text-font-main mb-0'>
                    {{ $t('packages.info') }}
                  </h5>
                </b-card-header>

                <b-card-body
                  class='position-relative justify-content-between px-5'
                >
                  <!-- form -->
                  <div class='d-flex flex-wrap'>

                    <b-col cols='12' md='4'>
                      <text-input
                        id='name'
                        v-model='form.name'
                        :label="$t('packages.form.name')"
                        rules='required'
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols='12' md='4'>
                      <div @click='$refs.imageinput.click()'>
                        <text-input
                          id='main-image'
                          v-model='form.image'
                          readonly
                          name='image'
                          :label="$t('packages.form.image')"
                          :placeholder="$t('form.image.placeholder')"
                          is-append
                          append-class='append-btn'
                          :append-text="$t('form.image.browse')"
                        />
                      </div>
                      <input
                        ref='imageinput'
                        type='file'
                        accept='image/png, image/jpeg'
                        style='display: none'
                        @change='uploadImageAction($event)'
                      />
                    </b-col>

                  </div>

                  <div class='d-flex flex-wrap'>

                    <b-col cols='12' md='12'>
                      <wameed-text-area
                        v-model='form.description'
                        :label="$t('packages.form.desc')"
                        :placeholder="$t('form.text.placeholder')"
                        rows='5'
                      />
                    </b-col>
                  </div>

                  <div class='d-flex flex-wrap'>

                    <b-col cols='12' md='4'>

                      <div class='wameed-input text-reg-14'>
                        <label class='text-font-main text-reg-14 '
                               style='margin-bottom: 8px;'>{{ $t('packages.form.dis_type') }}</label>
                        <treeselect
                          v-model='form.discount_type'
                          :multiple='false'
                          :options='discountTypes'
                          :limit='1'
                          :clearable='false'
                          :searchable='false'
                          :loadingText="$t('common.loadingText')"
                          :noChildrenText="$t('common.noChildrenText')"
                          :noOptionsText="$t('common.noOptionsText')"
                          :noResultsText="$t('common.noResultsText')"
                          :placeholder="$t('discounts.discounts.placeholder')"
                        >
                          <!--                          <div slot="before-list" >all</div>-->
                        </treeselect>
                      </div>
                    </b-col>

                    <b-col cols='12' md='4'>
                      <TextInput
                        v-model='form.discount'
                        :rules="'required'+maxDiscountValidation"
                        field-classes='w-100'
                        :label="$t('packages.form.discount')"
                        :placeholder="$t('form.text.placeholder')"
                        is-append
                        :append-text='getValueAppendText'
                        type='number'
                      />
                    </b-col>
                  </div>


                </b-card-body>
              </b-card>
            </b-col>


          </b-row>
          <b-card
            no-body
            class='wameed-card mb-5'
          >
            <div class='vendor-products-section'>

              <b-card-header class='align-items-start tab-title'>
                <h5 class='text-med-18 text-font-main'>
                  {{ $t('orders.products.title') }}
                </h5>
                <div class='search'>
                  <text-input
                    v-model='productSearch'
                    icon='search-icon'
                    is-append
                    input-classes='text-reg-14 '
                    :placeholder="$t('common.search') + ' ...'"
                    field-classes='mb-0 search-form-control'
                  />
                </div>
              </b-card-header>

              <div class='order-vendor-categories p-5'>

                <div v-for='cat in vendorProducts' :key='cat.id'>
                  <template v-if='cat.products && cat.products.length>0'>
                    <div class='category-name'>{{ cat.name }}</div>
                    <div class='order-vendor-products'>

                      <div
                        v-for="product in cat.products.filter((prod)=>prod.name.indexOf(productSearch) > -1 ||productSearch=='')"
                        :key='product.id'>
                        <product-card :data='product'
                                      @onClick='()=>{productModalData=null;onProductClick(product.id)}' />
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </b-card>


          <div class='w-table'>
            <wameed-tables
              :fields='tableFields'
              v-if='form.products'
              :items='form.products'
              :custimized-items="[
                { name: 'name' },
                { name: 'price' },
                { name: 'total' },
                { name: 'options' },
                { name: 'action' },
                ]"
            >
              <template slot='name' slot-scope='{ data }'>
                <name-tile
                  :name='data.item.name'
                  :image='data.item.image'
                  :data-id='data.item.id'
                />
              </template>


              <template slot='price' slot-scope='{ data }'>
                <check-price
                  :price='data.item.price'
                  :dis-price='0'
                />
              </template>
              <template slot='total' slot-scope='{ data }'>
                <check-price
                  :price='data.item.price*data.item.quantity'
                  :dis-price='0'
                />
              </template>
              <template slot='options' slot-scope='{ data }'>
                <div>

                  <div v-for='option in data.item.options'>
                    <div>
                      {{ option.name }}
                    </div>
                  </div>

                </div>
              </template>


              <template slot='action' slot-scope='{ data }'>


                <b-dropdown
                  variant='background'
                  class='table-option'
                  toggle-class='text-decoration-none rounded-10 px-2 py-2 '
                  menu-class='rounded-8'
                  no-caret
                  dropleft
                >
                  <template v-slot:button-content>
                    <vertical-dots-icon />
                  </template>

                  <b-dropdown-item
                    class='permission-edit-item'
                    v-if='isUpdate'
                    @click='()=>{productModalData = data.item; onProductClick(data.item.id)}'
                  >
                    <span class='text-regular-14 text-font-secondary'>

                      <edit-icon class='mx-2' />
                      {{ $t('btn.edit') }}
                    </span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    class='permission-delete-item'

                    @click='deleteModal = true; deleteItemData = data.item'>
                    <span class='text-regular-14  text-danger'>
                      <trash-icon class='mx-2 ' />
                      {{ $t('btn.delete') }}
                    </span>
                  </b-dropdown-item>

                </b-dropdown>
              </template>

            </wameed-tables>
          </div>

          <div class='px-2'>

            <b-card no-body class='wameed-card'>
              <b-card-footer class='px-4 py-3' style='border-color: white'>
                <div class='d-flex flex-wrap justify-content-between'>
                  <div class='px-4 py-3'>
                    <wameed-btn
                      classes='  text-med-14 text-white rounded-10'
                      :title="isUpdate?$t('packages.update_btn'):$t('packages.new_btn')"
                      :disabled='invalid'
                      type='submit'
                      variant='main'
                    />
                  </div>

                  <div class='px-4 py-3'>
                    <wameed-btn
                      classes='  text-med-14 text-font-secondary rounded-10 '
                      :title="$t('common.cancel')"
                      type='button'
                      variant='gray'
                      @onClick='goBack'
                    />
                  </div>
                </div>
              </b-card-footer>
            </b-card>
          </div>
        </div>
      </wameed-form>
    </section>
    <product-detail-modal
      :visible='showProductModal'
      :order-id='vendor_id'
      :cartProduct='productModalData'
      :showDelete='false'
      @close='showProductModal =false'
      @onSubmit='addToPackage'
    />


    <warning-modal
      variant='danger'
      iconName='trash-icon'
      :visible='deleteModal'
      @close='deleteModal = false'
      @submitAction='deleteAction'
      :title="$t('packages.modal.delete_product')"
      :subTitle="$t('packages.modal.delete_product_desc')"
      :btnTitle="$t('btn.delete')"
    />
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import {
  WameedBtn,
  WameedDropdown,
  WameedForm,
  WameedTextArea,
  WameedTextInput as TextInput
} from 'wameed-ui/dist/wameed-ui.esm';
import Treeselect from '@riophae/vue-treeselect';
import { DiscountTypes } from '@/enums/discountType.enum';
import ProductCard from '@/views/pages/orders/components/productCard.vue';
import ProductDetailModal from '@/views/pages/orders/components/productDetailModal.vue';
import NameTile from '@/components/nameTile.table.vue';
import CheckPrice from '@/components/checkPrice.vue';
import WarningModal from '@/components/WarningModal.vue';

export default {
  components: {
    WarningModal,
    CheckPrice, NameTile,
    ProductDetailModal,
    ProductCard,
    Treeselect,
    TextInput,
    WameedTextArea,
    WameedBtn,
    WameedDropdown,
    WameedForm,
    PageHeader
  },
  data() {
    return {
      productModalData: {},
      showProductModal: false,
      deleteModal: false,
      deleteItemData: null,
      discountTypes: DiscountTypes,
      vendor_id: '',
      advance_detail: false,
      productSearch: '',
      form: {
        name: '',
        image: '',
        description: '',
        products: []
      }

      ,

      tableFields: [
        {
          key: 'index',
          label: '#',
          sortable: true
        },

        {
          key: 'name',
          label: this.$i18n.t('packages.table.name'),
          sortable: false,
          tdClass: 'w-2/8'
        },

        {
          key: 'quantity',
          label: this.$i18n.t('packages.table.quantity'),
          sortable: false,
          tdClass: 'w-2/8'
        },


        {
          key: 'options',
          label: this.$i18n.t('packages.table.options'),
          sortable: false,
          tdClass: 'w-2/8'
        },


        {
          key: 'price',
          label: this.$i18n.t('table.price'),
          sortable: false,
          tdClass: 'w-1/8'
        },
        {
          key: 'total',
          label: this.$i18n.t('table.total'),
          sortable: false,
          tdClass: 'w-1/8'
        },


        {
          key: 'action',
          label: this.$i18n.t('table.options'),
          tdClass: 'w-1/8'
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      vendorProducts: 'vendor/packages/getProducts',
      detail: 'vendor/packages/getDetail'
    }),
    isUpdate() {
      return (this.$route.name === 'users-vendors-packages-update' && this.$route.params.prod_id);
    },
    isPercentDiscount() {
      let temp = '';
      if (this.form.discount_type) {
        temp = this.form.discount_type;
      }
      return temp === '%';
    },

    maxDiscountValidation() {
      if (this.isPercentDiscount) {
        return '|max_value:100|min_value:1';
      }
      return '';
    },

    getValueAppendText() {
      let temp = '';
      if (this.form.discount_type) {
        temp = this.form.discount_type;
      }

      if (temp) {
        return this.$i18n.t('discounts.discounts.append_' + temp);
      }
      return this.$i18n.t('discounts.discounts.append_text');
    }
  },


  async created() {

    this.vendor_id = this.$route.params.id;

    if (this.$route.name === 'users-vendors-packages-update' && this.$route.params.prod_id) {
      this.$store.dispatch('vendor/packages/detail', {
        id: this.$route.params.prod_id,
        vendor_id: this.vendor_id
      }).then(() => {
        this.form = this.detail;
      });
    } else {
      await this.$store.dispatch('vendor/packages/unsetDetail');
    }
    await this.$store.dispatch('vendor/packages/getVendorProducts', this.vendor_id);

  },
  methods: {
    deleteAction() {
      this.deleteModal = false;
      this.form.products = this.form.products.filter((item) => {
        return item.time !== this.deleteItemData.time;
      });
      // this._deleteData({id: this.deleteItemData.id, vendor_id: this.vendor_id});
    },
    addToPackage(data) {
      console.log(data);
      this.productModalData = null;

      const index = this.form.products.findIndex(item => item.time === data.time);
      let _temp = this.form.products.filter((item) => {
        return item.time !== data.time;
      });

      if (index === -1) {
        _temp.push(data);
      } else {
        _temp.splice(index, 0, data);
      }

      this.form.products = _temp;
      this.showProductModal = false;
    },


    onProductClick(id) {
      this.$store.dispatch('vendor/packages/getVendorProductDetail', {
        vendor_id: this.vendor_id, id
      }).then(() => {
          this.showProductModal = true;
        }
      );
    },
    uploadImageAction(event, type = 'upload', subImage = false) {
      this.$store.commit('loadingStart', null, { root: true });
      let files = [];
      if (type === 'upload') {
        files = event.currentTarget.files;
      } else {
        files = event;
      }
      if (this.dropped == 0) files = event;
      else files = event.currentTarget.files;

      if (!files.length) return;

      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('file', files[i]);

        this.$store.dispatch('utilities/uploadFiles', formData).then(response => {
          if (subImage) {
            this.form = {
              ...this.form,
              sub_image: response
            };
          } else {
            this.form = {
              ...this.form,
              image: response
            };
          }

          this.$store.commit('loadingFinish', null, { root: true });
        });
      }
    },


    goBack() {
      this.$router.push({ name: 'packages' });
    },

    submit() {
      this.form.vendor_id = this.vendor_id;


      if (this.$route.name === 'users-vendors-packages-update' && this.$route.params.prod_id) {
        this.form.id = this.$route.params.prod_id;
        this.$store.dispatch('vendor/packages/update', this.form);
      } else {
        this.$store.dispatch('vendor/packages/create', this.form);
      }

    }
  }
};
</script>
